
$theme-colors: (
  "primary": rgb(70, 84, 141),
  "info": rgb(199, 206, 238)
);

$input-placeholder-color: rgb(199, 199, 199);

$stickyz: 4;

@import '~bootstrap/scss/bootstrap.scss';


.diamond-detail-img-container {
  padding: 0em 2em;
}

.diamond-detail-img {
  max-width: 20em;
  cursor: pointer;
}

.diamond-detail-iframe{
  height: 18em;
  width: 22em;
}
.diamond-detail-iframe-enlarged{
  height: 65vh;
  width: 100%;
}
.diamond-detail-iframe-enlarge {
  cursor: pointer;
}
.diamond-detail-attachment-link {
  height: 100%;
  cursor: pointer;
  position: relative;
}

.diamond-detail-attachment-content {
  position: relative;
  top: 40%;
  font-size: 2.0em;
}

.diamond-detail-img-modal {
  max-width: 40%;
  margin: 0px auto;
  cursor: pointer;
  display: block;
}

.modal-90w {
  max-width: 90% !important;
}

.font-brand {
  font-family: Lora, serif;
}

.table-reset  > tbody > tr:nth-child(odd) > td,
.table-reset > tbody > tr:nth-child(even) > td {
  border: none;
  background-color: white;
}

.alert-badge {
  vertical-align: middle;
}

.button-option-color-label{
  height: 1.5em;
  width: 1.5em;
}

.shape-button-image{
  height: 2em;
}

.content-wide-img {
  margin-left: -40%;
  max-width: 180%;
  width: 180%;
}

.video-bg {
  position: relative;
  // height: 25em;
  // min-height: 25em;
  width: 100%;
  overflow: hidden;
}

.video-bg video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}

.video-bg .container {
  position: relative;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.77);
}

.video-bg .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  z-index: 1;
}

.home-top-section-left{
  background-image: url('/img/diamond-fade-right-2.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto;
}
.home-top-section-right{
  background-image: url('/img/diamond-fade-left-2.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: auto;
}

.home-top-section-head {
  padding-top: 2em;
  padding-bottom: 2em;
}

.layouts-cardpage-content {
  min-height: 70vh;
}

body{
  background-color: $light;
}

.layouts-contentpage-content {
  // background-image: url('/img/bg-sq-1-light.jpg');
  // background-repeat: no-repeat;
  // background-position: right bottom;
  // background-size: 40%;
  min-height: 70vh;
}

.component-appnavbar-navbar {
  background-image: url('/img/bg-horiz-2-light.jpg');
  background-repeat: no-repeat;
  background-position: 80% top;
  background-size: 50% auto;
}

.component-appfooter-footer {
  background-image: url('/img/bg-horiz-3.jpg');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 50% auto;
}

.result-table-row {
  cursor: pointer;
}

.result-table-row-opened{
}
.result-table-row-closed{
}

.result-table-row-detail{
  background-color: $white !important;
}

.result-table-row-detail-margin{
  background-color: $white !important;
}

.appnavbar-links a{

}

.large{
  font-size: 120%;
}

.row-icon{
  width: 1.2em;
  height: 1.2em;
}
.site-content a {
  text-decoration: underline;
}
.sort-head-asc::after {
  content: "▼";
}
.sort-head-desc::after {
  content: "▲";
}
.clickable {
  cursor: pointer;
}

.result-items-actions-row{
  background-color: $light;
  z-index: 1;
}

.sticky-bottom {
  z-index: $stickyz;
  position: sticky;
  bottom: 0;
}
.sticky-top {
  z-index: $stickyz;
  position: sticky;
  top: 0;
}
.small-button-inline {
  line-height: 1.2em;
}
.selected-results {
  border-bottom: 2px solid theme-color("secondary");
}
